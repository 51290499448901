(function (a, b, c, d) {
  a = 'https://c.jobcloud.ai/u/jobcloud/jobcloud.ai/qa/u.js';
  b = document;
  c = 'script';
  d = b.createElement(c);
  d.src = a;
  d.type = 'text/java' + c;
  d.async = true;
  d.defer = true;
  a = b.getElementsByTagName(c)[0];
  a.parentNode.insertBefore(d, a);
})();
